export default function deviceDetection() {
  const isMobile = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      console.log("TRUE");
      return true;
    } else {
      console.log("FALSE");
      return false;
    }
  };

  const isIOS = () => {
    if (/iPad|iPhone|iPod/.test(navigator.platform)) {
      return true;
    } else {
      return (
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform)
      );
    }
  };

  const isIpadOS = () => {
    return (
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform)
    );
  };

  const isMobileAny = () => {
    return isMobile() || isIpadOS() || isIpadOS();
  };
  return { isMobile, isIOS, isIpadOS, isMobileAny };
}
